/* You can add global styles to this file, and also import other style files */
@font-face {
  font-family: 'Uncut-Sans';
  src: url(./assets/fonts/Uncut-Sans-VF.ttf);
}

@font-face {
  font-family: 'Uncut-Sans-Regular';
  src: url(./assets/fonts/Uncut-Sans-Regular.otf);
  font-weight: 400;
}

@font-face {
  font-family: 'Uncut-Sans-Medium';
  src: url(./assets/fonts/Uncut-Sans-Medium.otf);
  font-weight: 500;
}
@font-face {
  font-family: 'Uncut-Sans-SemiBold';
  src: url(./assets/fonts/Uncut-Sans-Semibold.otf);
  font-weight: 600;
}

@font-face {
  font-family: 'Uncut-Sans-Bold';
  src: url(./assets/fonts/Uncut-Sans-Bold.otf);
  font-weight: 700;
}

:root {
  --font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol;
  --font-family: var(--font-uncut-sans);
  --font-uncut-sans-bold: 'Uncut-Sans-Bold';
  --font-uncut-sans-semibold: 'Uncut-Sans-SemiBold';
  --font-uncut-sans-medium: 'Uncut-Sans-Medium';
  --font-uncut-sans-regular: 'Uncut-Sans-Regular';
  --font-uncut-sans: 'Uncut-Sans';
}

p {
  font-family: var(--font-uncut-sans);
}

.font-uncut-sans {
  font-family: var(--font-uncut-sans);
}
.font-uncut-sans-regular {
  font-family: var(--font-uncut-sans-regular);
  font-weight: 400;
}
.font-uncut-sans-medium {
  font-family: var(--font-uncut-sans-medium);
  font-weight: 500;
}
.font-uncut-sans-semibold {
  font-family: var(--font-uncut-sans-semibold);
  font-weight: 600;
}
.font-uncut-sans-bold {
  font-family: var(--font-uncut-sans-bold);
  font-weight: 700;
}
