@import url('https://fonts.googleapis.com/css?family=Cormorant+Garamond');

// Custom Theming for NG-ZORRO
// For more information: https://ng.ant.design/docs/customize-theme/en
@import '../node_modules/ng-zorro-antd/ng-zorro-antd.less';

// Override less variables to here
// View all variables: https://github.com/NG-ZORRO/ng-zorro-antd/blob/master/components/style/themes/default.less

@font-family: 'Cormorant Garamond';

@primary-color: #d91e18;

@layout-header-background: #ffffff;
@layout-body-background: #ffffff;
@layout-header-color: #000;

@icon-color: #686868;
// @text-color-inverse: #d91e18;

@success-color: #079455;
@error-color: #d91e18;
